@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.form-body {
  max-height: calc(100vh - 10rem);
  max-width: 100%;
  overflow-y: auto;
}

.form-header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.25rem 0.5rem 1rem;
  width: calc(45rem - 1.5em + 0.75em + 2px - 1.5em);
}
.form-header .subtitle {
  margin-bottom: 0;
}

.form-body {
  width: 45rem;
  padding: 0 1rem 0.25rem 1rem;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
}
