@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.custom-modal-background, .custom-modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.custom-modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 40;
  min-width: 24rem;
}
.custom-modal.is-active {
  display: flex;
}

.custom-modal-background {
  background-color: rgba(10, 10, 10, 0.1);
}

.custom-modal-content {
  position: relative;
  max-height: calc(100vh - 5px);
  max-width: 85%;
  background-color: white;
  box-shadow: 3px 3px 3px rgba(10, 10, 10, 0.25), 0 0 0 1px rgba(10, 10, 10, 0.2);
  border: 1px solid #ffffff;
  z-index: 1;
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
}
.custom-modal-header > * {
  flex: 1;
  min-width: 0;
}

.custom-modal-close {
  flex: 0;
  min-width: auto;
}

.custom-modal-footer {
  background-color: #fafafa;
}
