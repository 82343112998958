/* -------------------------------------
 * * Bar container
 * * ------------------------------------- */
.progress-small-radial {
  float: left;
  margin-right: 0;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #023c3c;
}

/* -------------------------------------
 * * Optional centered circle w/text
 * * ------------------------------------- */  
.progress-small-radial .progress-small-overlay {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 50%;
  margin-left: 3px;
  margin-top: 3px;
  text-align: center;
}

.progress-medium-radial {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #023c3c;
}

.progress-medium-radial .progress-medium-overlay {
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  margin-top: 4px;
  border-radius: 50%;
  text-align: center;
  background-color: #ffffff;
}

/* -------------------------------------
 * * Bar container
 * * ------------------------------------- */
.progress-large-radial {
  position: relative;
  margin-left: 0;
  margin-top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #023c3c;
}

/* -------------------------------------
 * * Optional centered circle w/text
 * * ------------------------------------- */  
.progress-large-radial .progress-large-overlay {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  margin-left: 9px;
  margin-top: 9px;
  text-align: center;
}

/* -------------------------------------
 * * Mixin for progress-% class
 * * ------------------------------------- */
.progress-0 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(90deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-1 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(93.6deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-2 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(97.2deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-3 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(100.8deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-4 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(104.4deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-5 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(108deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-6 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(111.6deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-7 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(115.2deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-8 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(118.8deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-9 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(122.4deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-10 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(126deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-11 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(129.6deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-12 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(133.2deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-13 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(136.8deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-14 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(140.4deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-15 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(144deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-16 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(147.6deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-17 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(151.2deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-18 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(154.8deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-19 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(158.4deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-20 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(162deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-21 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(165.6deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-22 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(169.2deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-23 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(172.8deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-24 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(176.4deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-25 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(180deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-26 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(183.6deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-27 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(187.2deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-28 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(190.8deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-29 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(194.4deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-30 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(198deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-31 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(201.6deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-32 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(205.2deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-33 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(208.8deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-34 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(212.4deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-35 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(216deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-36 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(219.6deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-37 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(223.2deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-38 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(226.8deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-39 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(230.4deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-40 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(234deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-41 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(237.6deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-42 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(241.2deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-43 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(244.8deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-44 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(248.4deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-45 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(252deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-46 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(255.6deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-47 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(259.2deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-48 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(262.8deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-49 {
  background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%, transparent), linear-gradient(266.4deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-50 {
  background-image: linear-gradient(-90deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-51 {
  background-image: linear-gradient(-86.4deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-52 {
  background-image: linear-gradient(-82.8deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-53 {
  background-image: linear-gradient(-79.2deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-54 {
  background-image: linear-gradient(-75.6deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-55 {
  background-image: linear-gradient(-72deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-56 {
  background-image: linear-gradient(-68.4deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-57 {
  background-image: linear-gradient(-64.8deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-58 {
  background-image: linear-gradient(-61.2deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-59 {
  background-image: linear-gradient(-57.6deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-60 {
  background-image: linear-gradient(-54deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-61 {
  background-image: linear-gradient(-50.4deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-62 {
  background-image: linear-gradient(-46.8deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-63 {
  background-image: linear-gradient(-43.2deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-64 {
  background-image: linear-gradient(-39.6deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-65 {
  background-image: linear-gradient(-36deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-66 {
  background-image: linear-gradient(-32.4deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-67 {
  background-image: linear-gradient(-28.8deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-68 {
  background-image: linear-gradient(-25.2deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-69 {
  background-image: linear-gradient(-21.6deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-70 {
  background-image: linear-gradient(-18deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-71 {
  background-image: linear-gradient(-14.4deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-72 {
  background-image: linear-gradient(-10.8deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-73 {
  background-image: linear-gradient(-7.2deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-74 {
  background-image: linear-gradient(-3.6deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-75 {
  background-image: linear-gradient(0deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-76 {
  background-image: linear-gradient(3.6deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-77 {
  background-image: linear-gradient(7.2deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-78 {
  background-image: linear-gradient(10.8deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-79 {
  background-image: linear-gradient(14.4deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-80 {
  background-image: linear-gradient(18deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-81 {
  background-image: linear-gradient(21.6deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-82 {
  background-image: linear-gradient(25.2deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-83 {
  background-image: linear-gradient(28.8deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-84 {
  background-image: linear-gradient(32.4deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-85 {
  background-image: linear-gradient(36deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-86 {
  background-image: linear-gradient(39.6deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-87 {
  background-image: linear-gradient(43.2deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-88 {
  background-image: linear-gradient(46.8deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-89 {
  background-image: linear-gradient(50.4deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-90 {
  background-image: linear-gradient(54deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-91 {
  background-image: linear-gradient(57.6deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-92 {
  background-image: linear-gradient(61.2deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-93 {
  background-image: linear-gradient(64.8deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-94 {
  background-image: linear-gradient(68.4deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-95 {
  background-image: linear-gradient(72deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-96 {
  background-image: linear-gradient(75.6deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-97 {
  background-image: linear-gradient(79.2deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-98 {
  background-image: linear-gradient(82.8deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-99 {
  background-image: linear-gradient(86.4deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}

.progress-100 {
  background-image: linear-gradient(90deg, #023c3c 50%, transparent 50%, transparent), linear-gradient(270deg, #023c3c 50%, #dddddd 50%, #dddddd);
}
