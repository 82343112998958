._dropdown {
  position: relative;
}
._dropdown.is-open ._dropdown-menu {
  display: block;
}
._dropdown.is-right ._dropdown-menu {
  left: auto;
  right: 0;
}
._dropdown.is-up ._dropdown-menu {
  bottom: 100%;
  top: auto;
  padding-bottom: 0.25rem;
}
._dropdown .button {
  max-width: 12.5rem;
  background: transparent;
}

._dropdown-menu {
  display: none;
  position: absolute;
  margin-top: 0.25rem;
  z-index: 20;
  max-width: 25rem;
}

._dropdown-content {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  border: 1px solid #ffffff;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-menu-separator {
  display: flex;
  border-top: 1px solid #dbdbdb;
}
