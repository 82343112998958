.expandable-content.is-expanded > .expandable-body {
  display: block;
}
.expandable-content.is-expanded > div > .expandable-button:not(:hover) > .expandable-arrow, .expandable-content.is-expanded > div > .expandable-button:disabled > .expandable-arrow {
  opacity: 0;
}
.expandable-content.is-expanded > div > .expandable-header-bar {
  display: block;
}
.expandable-content.no-hover > div > .expandable-button:hover {
  cursor: default;
}
.expandable-content.no-hover > div > .expandable-button > .expandable-arrow {
  opacity: 0;
}
.expandable-content.use-header > div > .expandable-header-bar {
  display: block;
  cursor: pointer;
}
.expandable-content.use-header > div > button {
  margin-left: -31px;
}
.expandable-content.use-header:not(.no-hover) > div:hover > .expandable-button > .expandable-arrow {
  color: inherit;
}

.expandable-button {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.125rem 0.5rem;
  margin-left: -1.45rem;
  border: none;
  outline: none;
  background-color: transparent;
}
.expandable-button:hover:enabled {
  cursor: pointer;
}

.expandable-body,
.expandable-header-bar {
  display: none;
  width: 100%;
}

.expandable-arrow {
  width: 0.7rem;
  margin-right: 0.25rem;
}
