@font-face {
  font-family: "Titillium Web";
  src: url(./resources/fonts/titillium_web/TitilliumWeb-Regular.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Titillium Web";
  src: url(./resources/fonts/titillium_web/TitilliumWeb-SemiBold.ttf) format("truetype");
  font-weight: bold;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
}

html {
  overflow: hidden;
}
