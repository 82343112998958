.login-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.login-banner {
  padding: 0.125rem;
  background-color: #023c3c;
  max-width: 60rem;
  margin-bottom: 1.5rem;
}
.login-banner .label {
  color: white;
}
.login-banner .label:not(:last-child) {
  margin-bottom: 1rem;
}
.login-banner a {
  color: white;
}
.login-banner a:hover {
  color: #ed7d31;
}

.banner-content {
  padding: 0.875rem;
}

.login-banner-close {
  float: right;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}
.login-banner-close:focus {
  box-shadow: none;
}
.login-banner-close:hover:enabled img {
  filter: invert(55%) sepia(19%) saturate(2242%) hue-rotate(341deg) brightness(102%) contrast(86%);
}
.login-banner-close[disabled] {
  cursor: default;
  opacity: 0.5;
}

.login-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #292929;
  width: 100%;
  padding: 1rem;
}

.footer-table th, .footer-table td {
  text-align: start;
  padding: 0.125rem 1rem;
}
.footer-table .label {
  color: #ffffff !important;
}
.footer-table a {
  color: #dddddd;
}

.footer-socials {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(233, 233, 233, 0.05);
  margin-top: 1rem;
  padding-top: 0.5rem;
}
.footer-socials img {
  width: 32px;
  display: block;
}

.social-link:not(:last-child) {
  margin-right: 0.5rem;
}

.login-button {
  border: 1px solid #8c8c8c;
  height: 2.5rem;
  text-align: center;
  font-weight: 600;
}
