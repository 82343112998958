html {
  background-color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
}

*, :after, :before {
  box-sizing: inherit;
}

h1 {
  font-family: "Titillium Web";
  font-size: 1.32rem;
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

strong {
  font-weight: 600;
}

a {
  color: #3273dc;
  cursor: pointer;
  text-decoration: none;
}
a strong {
  color: currentColor;
}
a:hover {
  color: #363636;
}

body {
  color: #4a4a4a;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

body, button, input, select, textarea {
  font-family: "Arial", sans-serif;
}

img {
  height: auto;
  max-width: 100%;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

.textarea::-moz-placeholder, .input::-moz-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.textarea::-webkit-input-placeholder, .input::-webkit-input-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.textarea:-moz-placeholder, .input:-moz-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.textarea:-ms-input-placeholder, .input:-ms-input-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.textarea:hover, .input:hover, .is-hovered.textarea, .is-hovered.input {
  border-color: #b5b5b5;
}

.textarea:focus, .input:focus, .is-focused.textarea, .is-focused.input, .textarea:active, .input:active, .is-active.textarea, .is-active.input {
  border-color: #3273dc;
}

[disabled].textarea, [disabled].input {
  background-color: whitesmoke;
  border-color: whitesmoke;
  box-shadow: none;
  color: #7a7a7a;
}

[disabled].textarea::-moz-placeholder, [disabled].input::-moz-placeholder {
  color: rgba(122, 122, 122, 0.3);
}

[disabled].textarea::-webkit-input-placeholder, [disabled].input::-webkit-input-placeholder {
  color: rgba(122, 122, 122, 0.3);
}

[disabled].textarea:-moz-placeholder, [disabled].input:-moz-placeholder {
  color: rgba(122, 122, 122, 0.3);
}

[disabled].textarea:-ms-input-placeholder, [disabled].input:-ms-input-placeholder {
  color: rgba(122, 122, 122, 0.3);
}

.select select, .textarea, .input {
  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;
  color: #363636;
}

.button, .input, .textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  height: 2.25em;
  justify-content: flex-start;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  position: relative;
  vertical-align: top;
}

.button:active, .button:focus, .input:active, .input:focus {
  outline: none;
}

.button .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
  height: 1.5em;
  width: 1.5em;
}
.button .icon:first-child:not(:last-child) {
  margin-left: calc(-0.375em - 1px);
  margin-right: 0.1875em;
}
.button .icon:last-child:not(:first-child) {
  margin-left: 0.1875em;
  margin-right: calc(-0.375em - 1px);
}
.button .icon:first-child:last-child {
  margin-left: calc(-0.375em - 1px);
  margin-right: calc(-0.375em - 1px);
}

.is-small.textarea {
  border-radius: 2px;
}

.title,
.subtitle {
  word-break: break-word;
}
.title:not(:last-child),
.subtitle:not(:last-child) {
  margin-bottom: 0.5rem;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X'  */
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

/* clears the 'X' from calendar*/
input[type=date]::-webkit-clear-button {
  display: none;
}

/* pointer for file input */
input[type=file],
input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.subtitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.App {
  height: 100vh;
  overflow: auto;
}

.pages {
  height: calc(100% - 4rem);
}

.centered-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.display-input {
  margin: 0.75rem 0 !important;
}

.display-item {
  margin: 1rem 0 !important;
}

.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
}
.icon.is-small {
  height: 1rem;
  width: 1rem;
}
.icon.is-medium {
  height: 2rem;
  width: 2rem;
}
.icon.is-large {
  height: 3rem;
  width: 3rem;
}

.subtitle {
  color: #4a4a4a;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: auto 0;
}
.subtitle.is-6 {
  font-size: 1rem;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}
.title.is-1 {
  font-size: 4rem;
}
.title.is-2 {
  font-size: 2.5rem;
}
.title.is-5 {
  font-size: 1.25rem;
}

.round-image {
  border-radius: 50%;
}

.is-bold {
  font-weight: 600 !important;
  color: #515151 !important;
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.is-disabled {
  opacity: 0.5;
  cursor: default !important;
}

.is-hidden {
  display: none;
}

.is-heading {
  font-size: 0.96rem !important;
  font-weight: 600 !important;
  color: #515151 !important;
}

.is-required,
.is-invalid {
  border-color: #eb0014 !important;
}

.is-readonly,
.is-input-disabled {
  cursor: default;
  border-color: #dbdbdb;
  color: #363636;
}

[disabled] {
  cursor: default !important;
}

.is-selected {
  background-color: #e8e8e8;
}

.is-transparent {
  background: transparent;
}
