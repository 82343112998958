.checkbox-row {
  border: 1px solid transparent;
  padding: 0.25rem 0.75rem;
}
.checkbox-row .checkbox-container:not(:last-child) {
  margin-right: 1.5rem;
}

.inline-checkboxes > div {
  padding: 0.125rem 1rem;
}
.inline-checkboxes > div:first-child {
  padding-left: 0;
}

.checkbox-container {
  display: inline-flex;
  cursor: pointer;
  max-width: 100%;
}
.checkbox-container input[type=checkbox],
.checkbox-container input[type=radio] {
  position: relative;
  cursor: inherit;
  top: 0.075rem;
}
.checkbox-container.has-label input[type=checkbox],
.checkbox-container.has-label input[type=radio] {
  margin-right: 0.5rem;
}

.toggle-container {
  display: inline-flex;
}
.toggle-container:not(.is-readonly) {
  cursor: pointer;
}
.toggle-container .toggle-switch {
  top: 1px;
}
.toggle-container .label {
  margin-left: 0.5rem;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  height: calc(1.2rem + 2px);
  width: calc(2.5rem + 2px);
}
.toggle-switch.is-readonly {
  opacity: 0.5;
}
.toggle-switch.is-disabled .switch:before {
  content: none;
}
.toggle-switch input[type=checkbox] {
  display: none;
}
.toggle-switch input[type=checkbox]:checked ~ .switch {
  background-color: #036363;
}
.toggle-switch input[type=checkbox]:checked ~ .switch:before {
  transform: translateX(1.1rem);
}
.toggle-switch .switch {
  position: absolute;
  background-color: #dbdbdb;
  border: 1px solid #ffffff;
  border-radius: 46px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s;
}
.toggle-switch .switch:before {
  content: "";
  position: absolute;
  height: 0.8rem;
  width: 1rem;
  background-color: white;
  border: 1px solid #ffffff;
  border-radius: 30px;
  bottom: 0.21rem;
  left: 3px;
  transition: 0.1s;
}
