.input {
  width: 100%;
  font-size: 0.9rem;
  box-shadow: none;
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 2px;
  color: #363636;
}
.input:focus {
  border-color: #dbdbdb;
}
.input:hover:not([disabled]) {
  border-color: #b5b5b5;
}
.input:invalid, .input.is-required, .input.is-invalid {
  border-color: #eb0014 !important;
}
