@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.view-body {
  max-height: calc(100vh - 10rem);
  max-width: 100%;
  overflow-y: auto;
}

.view-header {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0 0.75rem 1.5rem;
  width: calc(60rem - 1.5em + 0.75em + 2px - 1.5em);
}

.view-header-left,
.view-header-right {
  display: flex;
  align-items: center;
}

.view-header-left {
  flex: 1;
  min-width: 0;
  margin-right: 1.5rem;
}

.view-body {
  width: 60rem;
  height: 50vh;
  padding: 0 1.5rem;
}

.view-footer {
  padding: 0.25rem;
}

.icon-subtitle {
  display: flex;
  align-items: center;
}

.object-id-subtitle {
  display: flex;
  flex-direction: column;
}

.id-label {
  font-size: 0.75rem;
}

.display-menu {
  margin-left: 0.25rem;
  margin-top: 0.2rem;
  margin-bottom: -0.2rem;
}
.display-menu .button {
  padding: 0 0.4rem;
}
.display-menu .icon {
  font-size: 1.2rem;
}
