.button {
  flex-shrink: 0;
  vertical-align: middle;
  font-size: 0.9rem;
  background-color: #fff;
  border-color: #dbdbdb;
  border-width: 1px;
  border-radius: 2px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding: calc(0.375em - 1px) 0.75em;
  text-align: center;
  white-space: nowrap;
}
.button.is-fullwidth {
  display: flex;
  width: 100%;
}
.button:not(.is-img) {
  min-width: 4rem;
}
.button:focus.is-img {
  box-shadow: none;
}
.button:focus:not(.no-focus-border) {
  border-color: #dbdbdb;
}
.button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}
.button:hover:enabled {
  background-color: #fafafa !important;
  border-color: #b5b5b5;
  color: #363636 !important;
}
.button:active:enabled {
  border-color: #b5b5b5 !important;
  background-color: #dbdbdb !important;
}
.button.is-white {
  background-color: white;
  border-color: transparent;
  color: #0a0a0a;
}
.button.is-white:enabled:hover, .button.is-white:enabled.is-hovered {
  background-color: #f9f9f9;
  border-color: transparent;
  color: #0a0a0a;
}
.button.is-white:enabled:focus, .button.is-white:enabled.is-focused {
  border-color: transparent;
  color: #0a0a0a;
}
.button.is-white:enabled:focus:not(:active), .button.is-white:enabled.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25);
}
.button.is-white:enabled:active, .button.is-white:enabled.is-active {
  background-color: #f2f2f2;
  border-color: transparent;
  color: #0a0a0a;
}
.button.is-clear {
  border: none;
  background: transparent;
}
.button.is-square {
  border-radius: 0;
}
.button[disabled] {
  cursor: default;
  box-shadow: none;
  opacity: 0.5;
  background-color: white;
  border-color: #dbdbdb;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}
.button-group.align-start {
  justify-content: flex-start;
}
.button-group > *:not(:first-child) {
  margin-left: 0.5rem;
}

.add-remove-buttons {
  display: flex;
}
.add-remove-buttons.is-read-only:not(.no-padding) {
  padding: 0.3375rem 0.6rem;
}
.add-remove-buttons .button {
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.add-remove-buttons .label {
  margin: auto;
}
.add-remove-buttons .label:not(.is-read-only) {
  margin: auto 0 auto 0.5rem;
}

.add-button-header {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.add-button-header > *:not(:first-child) {
  margin-left: 0.5rem;
}
