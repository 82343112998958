.registration-box {
  width: 28rem;
  max-width: 95%;
  border: 1px solid #ffffff;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.registration-header {
  font-family: "Titillium Web";
  background-color: #fafafa;
  padding: 0.5rem 1.5rem;
}

.registration-form {
  padding: 1rem 1.5rem 1rem;
}

.login-captcha {
  display: flex;
  justify-content: center;
}
