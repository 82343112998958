.search-bar {
  display: flex;
  background-color: white;
}
.search-bar:not(.is-disabled):hover {
  background-color: #fafafa;
}
.search-bar .input,
.search-bar .button {
  box-shadow: none;
  background: transparent;
}
.search-bar .input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.search-bar .input:not(:hover) {
  border-right: none;
}
.search-bar .button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.search-bar .button:not(:hover) {
  border-left: none;
}
