@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.horizontal-tab:focus:not(.no-focus),
.horizontal-route-tab:focus:not(.no-focus) {
  outline: none;
}
.horizontal-tab:focus:not(.no-focus):not(.is-disabled),
.horizontal-route-tab:focus:not(.no-focus):not(.is-disabled) {
  background-color: #fafafa;
}

.horizontal-tab:hover:not(.is-disabled):not(.no-hover),
.horizontal-route-tab:hover:not(.is-disabled):not(.no-hover) {
  background-color: #fafafa;
}
.horizontal-tab:hover:not(.is-disabled):not(.no-hover):not(.no-pointer),
.horizontal-route-tab:hover:not(.is-disabled):not(.no-hover):not(.no-pointer) {
  cursor: pointer;
}

.tabbed-page {
  display: flex;
  height: 100%;
}

.tabbed-page-tabs {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 25%;
  max-width: 20rem;
  border-right: 1px solid #e8e8e8;
}

.tabbed-page-display {
  flex-grow: 1;
  padding: 2rem;
  overflow: auto;
}

.tabs-list-header {
  margin: 1rem 2.25rem 1rem;
}

.tabs-list {
  overflow-y: auto;
}

.horizontal-tab,
.horizontal-route-tab {
  display: flex;
  align-items: center;
  padding: 0.75rem 2.25rem;
  background-color: white;
}
.horizontal-tab .icon,
.horizontal-route-tab .icon {
  margin-right: 0.25rem;
}
.horizontal-tab .label:not(:first-child),
.horizontal-route-tab .label:not(:first-child) {
  margin-left: 0.25rem;
}
.horizontal-tab.is-active, .horizontal-tab.active,
.horizontal-route-tab.is-active,
.horizontal-route-tab.active {
  background-color: #e8e8e8 !important;
  border-left: 8px solid #036363 !important;
  padding-left: calc(2.25rem - 8px);
}
