@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.is-active.table-row, .is-selected.table-row {
  background-color: #e8e8e8 !important;
}

.table-row:focus:not(.no-focus) {
  outline: none;
}
.table-row:focus:not(.no-focus):not(.is-disabled) {
  background-color: #fafafa;
}

.table-row:hover:not(.is-disabled):not(.no-hover) {
  background-color: #fafafa;
}
.table-row:hover:not(.is-disabled):not(.no-hover):not(.no-pointer) {
  cursor: pointer;
}

.table,
.display-table,
.forms-table,
.settings-table {
  display: table;
  table-layout: auto;
}

.table,
.display-table {
  width: 100%;
}

.table .table-cell,
.forms-table .table-cell {
  border: none !important;
}
.table .table-cell:not(.no-padding),
.forms-table .table-cell:not(.no-padding) {
  padding: 0.375rem 0;
}
.table .table-cell:not(:first-child),
.forms-table .table-cell:not(:first-child) {
  padding-left: 1rem;
}
.table .table-row:not(.is-disabled):hover,
.forms-table .table-row:not(.is-disabled):hover {
  cursor: default !important;
  background-color: transparent !important;
}
.table .table-row:last-child .table-cell,
.forms-table .table-row:last-child .table-cell {
  padding-bottom: 0;
}

.forms-table .table-cell {
  vertical-align: middle;
}

.table-header-group {
  display: table-header-group;
  background-color: #fafafa;
}
.table-header-group.is-nowrap .table-header {
  white-space: nowrap;
}

.table-header {
  display: table-cell;
  padding: 0.5rem 1rem;
  vertical-align: middle;
  border-top: 1px solid #e8e8e8;
}
.table-header.has-bottom-border {
  border-bottom: 1px solid #e8e8e8;
}
.table-header.no-wrap {
  white-space: nowrap;
}
.table-header .enable-cell {
  width: 4rem;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}
.table-row.align-middle .table-cell {
  vertical-align: middle;
}
.table-row:first-child .table-cell {
  border-top: 1px solid #e8e8e8;
}
.table-row.padding-med .table-cell {
  padding-top: 1rem;
}

.table-cell {
  display: table-cell;
  vertical-align: top;
  border-bottom: 1px solid #e8e8e8;
}
.table-cell:not(.no-padding) {
  padding: 0.5rem 1rem;
}
.table-cell.no-wrap {
  white-space: nowrap;
}
.table-cell .enable-cell {
  padding-top: 0.75rem;
  padding-right: 0.5rem;
}

.settings-row {
  display: table-row;
}

.settings-label-cell,
.settings-value-cell {
  display: table-cell;
  padding-top: 0.1rem;
}

.settings-label-cell {
  width: 12rem;
  padding-right: 4rem;
  white-space: nowrap;
  vertical-align: top;
}

.settings-value-cell {
  vertical-align: middle;
}

.settings-row-separator {
  display: table-cell;
  padding-top: calc(0.35em - 1px);
  padding-bottom: 0.35em;
}
.settings-row-separator.is-small {
  padding: 0.15em 0;
}
.settings-row-separator.is-large {
  padding: 0.75rem 0;
}

.table-header:first-child, .table-cell:first-child {
  border-left: 1px solid #e8e8e8;
}
.table-header:last-child, .table-cell:last-child {
  border-right: 1px solid #e8e8e8;
}
