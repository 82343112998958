.app-header {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
  padding: 0 1.5rem;
}

.logo {
  vertical-align: middle;
  height: 2.4rem;
}

.menu-dropdown {
  margin-left: 0.25rem;
}
.menu-dropdown .selectable-option {
  justify-content: space-between;
}
.menu-dropdown .button {
  font-size: 1.25rem;
}
.menu-dropdown .button:hover {
  background-color: transparent !important;
  opacity: 0.8;
}
.menu-dropdown .button:active {
  background-color: transparent;
  opacity: 0.5;
}
