@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.is-active.selectable-item, .is-selected.selectable-item {
  background-color: #e8e8e8 !important;
}

.selectable-item:focus:not(.no-focus) {
  outline: none;
}
.selectable-item:focus:not(.no-focus):not(.is-disabled) {
  background-color: #fafafa;
}

.selectable-item:hover:not(.is-disabled):not(.no-hover) {
  background-color: #fafafa;
}
.selectable-item:hover:not(.is-disabled):not(.no-hover):not(.no-pointer) {
  cursor: pointer;
}

.selectable-item {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  min-height: 1.95rem;
}
.selectable-item.is-centered {
  justify-content: center;
  text-align: center;
}
.selectable-item.is-bordered {
  border-bottom: 1px solid #dbdbdb;
}
.selectable-item.is-spaced {
  justify-content: space-between;
}
.selectable-item.is-column {
  flex-direction: column;
}

.selectable-item__type > .label:nth-child(2) {
  flex: 1;
  text-align: center;
}

.selectable-item__email > .label {
  width: 50%;
  padding: 0 0.5rem;
  text-align: left;
}
.selectable-item__email > .label:first-child {
  text-align: right;
}

.dot-span {
  height: 0;
  width: 0;
  border: 1px solid #595959;
}
