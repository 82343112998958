@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.label.is-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label {
  display: block;
  color: #363636;
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
  cursor: inherit;
  max-width: 100%;
}
.label.is-center {
  text-align: center;
}
.label.is-note {
  font-size: 0.84rem;
}
.label.is-wordwrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.label.is-wordwrap.break-all {
  word-break: break-all;
}
.label.no-wrap {
  white-space: nowrap;
}
.label.is-small {
  font-size: 0.9rem;
}
.label.is-medium {
  font-size: 1.25rem;
}
.label.is-large {
  font-size: 1.6rem;
}
.label.is-error {
  color: #eb0014;
  font-size: 0.75rem !important;
}

.copy-label {
  margin: 0;
}
.copy-label .button-group {
  margin-top: 0.25rem;
}

.status-label {
  display: flex;
  align-items: center;
  flex: 1;
}

.link-label {
  color: #3273dc;
  cursor: pointer;
  width: fit-content;
}
.link-label:hover {
  color: #363636;
}
