.popup-header {
  padding: 0.5rem 0.75rem;
}

.popup-body {
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  min-width: 24rem;
  max-width: 40rem;
  min-height: 6rem;
  max-height: 24rem;
  overflow-y: auto;
}
.popup-body .icon {
  flex-shrink: 0;
}
.popup-body > *:not(.icon) {
  margin: auto 0 auto 1rem;
}

.popup-footer {
  padding: 0.5rem;
}

.disconnect-popup {
  text-align: center;
  padding: 3rem 2.5rem;
}
